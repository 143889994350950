<template>

<div class="list">
    <a :href="'/fund/publish/success?projuuid='+projData.uuid" class="list_link">
        <div class="content">
            <img class="icon" v-lazy="projData.project_thumb" >
            <div class="project_tit">{{projData.title}}</div>
            <div class="time_and_status">{{projData.created_at}} 创建</div>
            <div class="fund_and_withdraw"><span class="fund">已筹 {{projData.accumulative_number/100}} 元</span><span :class="{withdraw:true,  withdraw_grey:needDisable}">待提现 <strong>{{projData.wallets_balance/100}}</strong> 元</span></div>
        </div>
        <div class="buttons">
             <router-link :to="'/fund/publish/success?projuuid='+projData.uuid">查看项目</router-link>
             <router-link v-if="projData.state==2&&projData.accumulative_number>0" :to="'/fund/withdraw/entry?projuuid='+projData.uuid"  :class="{hide:needDisable}">立即提现</router-link>
        </div>
    </a>
</div>

</template>

<script>
export default {
    data(){
        return {

        }
    },
    computed:{
        needDisable:function(){

            return this.projData.wallets_balance == 0;
        }
    },
    props:['projData']
}
</script>

<style lang="less" scoped>

    .list {
        list-style: none;
        margin-bottom: 30px;
        background: #F5F6F8;
        padding: 15px;
        border-radius: 4px;

        .list_link {
            display: block;
        }

        .buttons {
            text-align: right;
            a {
                display: inline-block;
                padding: 0;
                margin: 0 0 0 20px;
                color: #009BFF;
                font-size: 15px;
                font-weight: 700;

                &:hover {
                    color: #088ADD;
                }
            }
            a.disabled{
                color: #999999;
            }
            a.hide{
                display: none;

            }
        }

        .content {
            margin-bottom: 18px;
            position: relative;
            padding-left: 85px;
        }

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 70px;
            height: 70px;
            overflow: hidden;
            // background: url(../img/project_faceicon.png) no-repeat;
            // background-size: cover;
            border-radius: 4px;
        }

        .project_tit {
            font-size: 17px;
            color: #333;
            font-weight: 500;
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 3px;
            text-align: justify;

            .money {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 17px;
                color: #019CFF;
                text-align: right;
            }

            .buttons {
                display: block;
                width: 100%;
                background: #000;
            }
        }

        .time_and_status {
            margin-bottom: 10px;
            text-align: left;
            font-size: 13px;
            color: #999;
            line-height: 1;
            .status {
                float: right;
            }
        }

        .fund_and_withdraw {
            font-size: 13px;
            color: #374754;
            strong {
                font-weight: 400;
                color: #009BFF;
            }
            .fund {
                margin-right: 10px;
            }
            .withdraw {
                margin-left: 10px;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    left: -10px;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background: #374754;
                }
            }

            .withdraw_grey {
                color: #aaa;
                    strong {
                    font-weight: 400;
                    color: #aaa;
                }
            }

        }
    }

</style>
