import mmcAjax from "@/common/js/mmcAjax"
export default {
    getList(n){
        let url = `${window.APIBasePath}mmc/user-center/project/list/${n}`
        
        return mmcAjax({
            method: 'get',
            url,
          })
    }
}