<template>
    <div class="nodata-wraper">
         <img src="../img/not-data.png" class="not-data" />
        <span class="not-data-desc">暂无记录</span>
        <button
               
                @click="toPublish"
                class="default-btn active">
                发起筹款
            </button>
    </div>
</template>

<script>
export default {
    methods:{
        toPublish(){
            this.$router.push("/fund/publish/guidance")
        }
    }
};
</script>

<style lang="less" scoped>
.nodata-wraper{
    margin: 0 20px;
}
.not-data {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto 20px;
}

.not-data-desc {
    font-size: 17px;
    color: #333333;
    text-align: center;
    line-height: 54px;
}
.default-btn {
    
        display: block;
        width: 100%;
        height: 100%;
        background: #EFEFEF;
        line-height: 1;
        font-size: 18px;
        color: #251a1a;
        text-align: center;
        line-height: 1;
        border: none;
        padding: 15px 0;
        color: #999;
        pointer-events: none;
        border-radius: 5px;
    }

    .default-btn.active {
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
        color: #FFFFFF;
        pointer-events: auto;
    }
</style>