<template>
    <div v-if="dataOk"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loadBusy"
        infinite-scroll-distance="200"
    >
        <div class="wrap">
            <div class="page_title">我的筹款</div>
            <div>
                <card
                    v-for="item in listData"
                    :key="item.id"
                    v-bind:projData="item"
                ></card>
            </div>
        </div>

        <div class="h5ui-loading processing hidden" style="display: none">
            <i class="loading-processing_icon"></i>
            <span>正在加载</span>
        </div>
        <div class="h5ui-loading success hidden" style="display: none">
            <span class="loading-line"> 只有这么多了 </span>
        </div>
        <nodata :class="{ itemHide: nodataHide }"></nodata>
    </div>
</template>

<script>
import card from './cpt/card.vue';
import nodata from './cpt/nodata.vue';
import api from './js/api';
import { authChecker,shareCtrl } from '@/common/js/mmcModule';
import infiniteScroll from 'vue-infinite-scroll';
import Vue from 'vue';
import { hideLoading } from '../../../common/js/modalloading';
Vue.use(infiniteScroll);
export default {
    data() {
        return {
            listData: [],
            nextPage: 1,
            page_total: 1,
            loadBusy: true,
            nodataHide: true,
            dataOk:false
        };
    },
    components: {
        card,
        nodata,
    },
    methods: {
        loadMore() {
            if (this.nextPage > this.page_total || this.loadBusy) return;
            console.log(this.nextPage, this.loadBusy);
            this.loadBusy = true;

            showLoading('加载中');

            setTimeout(() => {
                api.getList(this.nextPage).then((res) => {
                    hideLoading();
                    this.listData.push.apply(this.listData, res.data.list);
                    this.nextPage++;
                    this.loadBusy = false;
                    
                });
            }, 500);
        },
    },
    created() {
        authChecker.check({ notneedphone: 1 }, (res) => {
            showLoading('加载中');
            api.getList(this.nextPage).then((res) => {
                hideLoading();
                console.log(res);
                this.listData.push.apply(this.listData, res.data.list);

                // this.nextPage = res.next;
                this.nodataHide = this.listData.length > 0 ? true : false;
                this.loadBusy = false;
                this.page_total = res.data.page_total;
                this.nextPage++;
                this.dataOk = true
                console.log(this.nextPage);
            });
            shareCtrl.init();
        });
    },
};
</script>

<style lang="less" scoped>
.wrap {
    padding: 0 15px 15px;
    text-align: left;
    .page_title {
        margin: 35px 0 40px;
        font-size: 34px;
        font-weight: 700;
    }
}

.itemHide {
    display: none;
}
</style>